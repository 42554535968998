/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.scss';
import '../css/main.css';
import '../css/components.css';
import 'bootstrap-colorpicker/dist/css/bootstrap-colorpicker.css';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
import $ from 'jquery';

require('bootstrap');
require('bootstrap-colorpicker');
// require('./custom.js');

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

$(document).ready(function() {
    var downloadlink = function() {
        var href = $('#download').attr('href').split('?')[0];
        return href + '?format=' + $('#format').data('format') + '&size=' + $('#size').data('size');
    }
    setTimeout(function () {
        $('.message').remove();
    }, 5000);
    $('#searchall').click(function (e) {
        e.preventDefault();
        $('#search').val('');
        $('#form').submit();
    });
    $('#format').next('div').children('a').click( function(e) {
        e.preventDefault();
        $('#format').data('format', $(this).data('format')).html('Format: '+$(this).text()+' ');
        $('#download').attr('href',downloadlink());
    });
    $('#size').next('div').children('a').click( function(e) {
        e.preventDefault();
        $('#size').data('size', $(this).data('size')).html('Size: '+$(this).text()+' ');
        $('#download').attr('href',downloadlink());
    });
    $('body').on('click', '[data-toggle="modal"]', function(e) {
        e.preventDefault();
        $.get($(this).data("remote"), function(data) {
            $('body').append(data);
            $('#modal-delete').modal().on('hidden.bs.modal', function() {
                $(this).remove();
            });
        });
    });
    $('body').on('click', '#btn-delete', function(e) {
        $.get($(this).data('action'), function(data) {
            if ('OK' === data.status) {
                if (data.location) {
                    location.href = data.location;
                }
                else {
                    location.reload();
                }
            }
        });
        $('#modal-delete').modal('hide').remove();
    });
    $('body').on('click', '.btn-share', function(e) {
        e.preventDefault();
        $.get($(this).data('share'), function(data) {
            var link = "mailto:";
            link += "?";
            if( data.subject && data.subject.length > 0 ) link += "subject="+encodeURIComponent(data.subject)+"&";
            if( data.body && data.body.length > 0 ) link += "body="+encodeURIComponent(data.body);
            location.href = link;
        }, "json");
    });
    $('#fontcolorpicker').colorpicker({
        format: 'hex',
        fallbackColor: '#ffffff',
        autoInputFallback: false
    });
    $('.custom-file-input').on('change', function() {
        $(this).next('.custom-file-label').html($(this).val().split('\\').pop());
    })
});
